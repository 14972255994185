import React, { useState } from 'react'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'

import { useEffect } from 'react'
import { listarMeusProjetos, setFormProjetoLista } from '../redux/meusProjetosActions'
import { Button, IcoHelpCircle, IcoSearch, Input, Popover, Select } from '../../../components'
import { history } from '../../../helpers';
import Projeto from './projeto/projeto';
import Empresas from './empresas/empresas';
import Estabelecimentos from './estabelecimentos/estabelecimentos';
import Produtos from './produtos/produtos';

export default () => {
  const dispatch = useDispatch()
  const {idProjetos, idPasso} = useParams()
  const { sistema } = useSelector(state => state.layoutState)
  const nls = require(`../nls/${sistema.idioma}.json`)
  console.error(sistema.idioma, 'sistema.idioma em configurar')
  const { listMeusProjetos, paginate, meuProjeto } = useSelector(state=> state.meusProjetosState)
  const [form, setForm] = useState({projeto: {}, search:''})
  const selectedProjectHas = listMeusProjetos.find(e=> String(e.idProjeto) === String(idProjetos))

  useEffect(()=> {
    if (!idPasso) {
      history.push(`/meusprojetos/${idProjetos}/configurar/${listTab[0].id}`)
    }
  }, [idPasso])

  useEffect(()=> {
    dispatch(listarMeusProjetos())
  }, [])

  useEffect(()=> {
    setForm({...form, projeto: selectedProjectHas?selectedProjectHas:{}})
    dispatch(setFormProjetoLista({name: 'projeto', value: selectedProjectHas?selectedProjectHas:{}}))
  }, [selectedProjectHas])

  const listTab = [
    {
      id: 'projeto',
    },
    ...meuProjeto.projeto.temMenuEmpresa ? [{
      id: 'empresas',
    }]:[],
    ...meuProjeto.projeto.temMenuEstabelecimento ? [{
      id: 'estabelecimentos',
    }]:[],
    ...meuProjeto.projeto.temMenuProduto ? [{
      id: 'produtos',
    }]:[],
  ]
  
  const handleChange = e => {
    console.log(e, 'handleChange');
    // dispatch(setFormProjeto({name: e.name, value: e.value, tipo: 'configurar'}))
  }

  const contentPage = e =>{
    switch (e) {
      case 'projeto':
        return <Projeto />;
      case 'empresas':
        return <Empresas />;
      case 'estabelecimentos':
        return <Estabelecimentos />;
      case 'produtos':
        return <Produtos />;
      default:
        return <div></div>;
    }
  }

  console.log({idPasso,form, selectedProjectHas}, 'idPassoidPasso');
  return (
    <div className='box-meus-projetos-configurar'>
      <div className='box-content'>
        <div className='box-content-topinfo'>
          <h3>{nls.configurar.title} <Popover>Essta são as configurações do projeto</Popover></h3>
          <div className='actions-tab'>
            {
              listTab.map(e=> {
                return <Button
                  key={e.id}
                  color={idPasso === String(e.id)?'primary':'default'}
                  variant='underline'
                  onClick={()=> history.push(`/meusprojetos/${idProjetos}/configurar/${e.id}`)}
                >{nls.configurar[e.id]}</Button>
              })
            }
          </div>
        </div>
        <div className='box-meus-projetos-configurar-search'>
          <Select
            name='projeto'
            label=''
            options={listMeusProjetos}
            selected={form.projeto}
            action={(e)=> history.push(`/meusprojetos/${e.value.idProjeto}/configurar/${idPasso}`)}
            selectedItem={false}
            textCustom={nls.select}
            optionLabel='descricao'
            optionValue='idProjeto'
          />
          <Input
            name='search'
            label=''
            value={form.search}
            placeholder={nls.configurar?.searchPlaceholde}
            action={(e) => setForm({...form, [e.name]: e.value})}
            left={<Button
              type='link'
              color='default'
              onClick={()=> null}
            ><IcoSearch /></Button>}
          />
        </div>
        {
          idProjetos ? <div>
            {contentPage(idPasso)}
          </div>:null
        }
      </div>
    </div>
  )
}
