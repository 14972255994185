import React from 'react'
import { IcoCog, IcoComentarios, IcoDados, IcoEmpresa, IcoEnvelope, IcoGrupos, IcoList, IcoLogOut, IcoUsers } from "../components"
import { IcoRedes } from "../components/icon/custom/icoredes"
import { IcoProdutos } from "../components/icon/custom/icoprodutos"
import { IcoPlanos } from "../components/icon/custom/icoplanos"

export const menuItensHeader = (nls) => {
  return [
    { 
      id: "visualizarProjeto", 
      label: nls.menus.visualizar, 
      go: "/meusprojetos", 
      icon: '' 
    },
    { 
      id: "ConfigurarProjeto", 
      label: nls.menus.configurar, 
      go: "/meusprojetos/novo/configurar", 
      icon: '' 
    },
    { 
      id: "CriarProjeto", 
      label: nls.menus.criar, 
      go: "/meusprojetos", 
      icon: '' 
    },
    { 
      id: "ComprarProjeto", 
      label: nls.menus.comprar, 
      go: "/projetos", 
      icon: '' 
    },
  ]
}
  
  
export const menuItens = () => {
  return [
    { 
      id: "Resumodoprojeto", 
      label: "Resumo do projeto", 
      go: "/resumoprojeto", 
      icon: <IcoDados /> 
    },
    { 
      id: "planos", 
      label: "Planos", 
      icon: <IcoPlanos />,
      sub: [
        { 
          id: "Plano7Dias", 
          label: "Plano 7 Dias", 
          go: "/pl7",
        }   
      ]
    },
    { 
      id: "empresas", 
      label: "Empresas", 
      icon: <IcoEmpresa />,
      sub: [
        { 
          id: "CSAT", 
          label: "CSAT", 
          go: "/csat",
        },
        { 
          id: "NPS", 
          label: "NPS", 
          go: "/nps",
        },
        { 
          id: "CSATConcorrentes", 
          label: "CSAT: comparação com concorrentes", 
          go: "/csatconcorrentes",
        },
        { 
          id: "NPSConcorrentes", 
          label: "NPS: comparação com concorrentes", 
          go: "/npstconcorrentes",
        }
      ]
    },

    { 
      id: "redessociais", 
      label: "Redes Sociais", 
      icon: <IcoRedes />,
      sub: [
        { 
          id: "CSATREDES", 
          label: "CSAT", 
          go: "/csatredescsatcliente",
        },
        { 
          id: "NPSREDES", 
          label: "NPS", 
          go: "/npsredescsatcliente",
        },
        { 
          id: "CSATConcorrentesRedes", 
          label: "CSAT: comparação com concorrentes", 
          go: "/csatredesconcorrentes",
        },
        { 
          id: "NPSConcorrentesRedes", 
          label: "NPS: comparação com concorrentes", 
          go: "/npsredesconcorrentes",
        },
        { 
          id: "INDICADORESREDES", 
          label: "Indicadores", 
          go: "/indicadoresredes",
        }

      ]
    },

    { 
      id: "lojas", 
      label: "Lojas", 
      icon: <IcoGrupos />,
      sub: [
        { 
          id: "Resumodosgrupos", 
          label: "Resumo dos grupos", 
          go: "/resumogrupos",
        },
        { 
          id: "RankingClienteCSAT", 
          label: "CSAT dos estabelecimentos", 
          go: "/csatestabelecimentos",
        },
        { 
          id: "RankingClienteNPS", 
          label: "NPS dos estabelecimentos", 
          go: "/npsestabelecimentos",
        },
        { 
          id: "RankingCSAT", 
          label: "CSAT dos estabelecimentos: comparação com concorrentes", 
          go: "/csatestabelecimentosconcorrentes",
        },
        { 
          id: "RankingNPS", 
          label: "NPS dos estabelecimentos: comparação com concorrentes", 
          go: "/npsestabelecimentosconcorrentes",
        },
        { 
          id: "Indicadores", 
          label: "Indicadores", 
          go: "/indicadores",
        },
        { 
          id: "“AnáliseSWOT", 
          label: "Análise SWOT", 
          go: "/analiseswot",
        }
      ]
    },

    { 
      id: "Produtos", 
      label: "Produtos", 
      icon: <IcoProdutos />,
      sub: [
        { 
          id: "Resumodosgruposprodutos", 
          label: "Resumo dos grupos", 
          go: "/resumogruposprodutos",
        },
        { 
          id: "CSATPRODUTOS", 
          label: "CSAT", 
          go: "/csatprodutoscsatcliente",
        },
        { 
          id: "NPSPRODUTOS", 
          label: "NPS", 
          go: "/npsprodutosnpscliente",
        },
        { 
          id: "RankingClienteCSAT", 
          label: "CSAT dos produtos: comparação com concorrentes", 
          go: "/csatprodutosconcorrentes",
        },
        { 
          id: "RankingClienteNPS", 
          label: "NPS dos produtos: comparação com concorrentes", 
          go: "/npsprodutosconcorrentes",
        },
        { 
          id: "INDICADORESPRODUTOS", 
          label: "Indicadores", 
          go: "/indicadoresprodutos",
        },
        { 
          id: "“AnáliseSWOTProdutos", 
          label: "Análise SWOT", 
          go: "/analiseswotprodutos",
        }
      ]
    },

    { 
      id: "Comentarios", 
      label: "Comentários", 
      go: "/comentarios", 
      icon: <IcoComentarios /> 
    },
    { 
      id: "ComentariosNovo", 
      label: "ComentáriosNovo", 
      go: "/comentariosnovos", 
      icon: <IcoComentarios /> 
    }
  ]
}

export const menuUserInfo = (e, nls) => {
  return [
    { 
      id: "Dados ecadastro", 
      label: nls.menus.dadosCadastro, 
      action: "modalMeusDadosCadastro", 
      icon: <IcoCog /> 
    },
    ...e?.VerGerenciarUsuarios?[{ 
      id: "Gerenciarusuários", 
      label: nls.menus.gerenciarUsuarios,  
      go: "/usuarios", 
      icon: <IcoUsers /> 
    }]:[],
    ...e?.VerHistoricoFaturas?[{ 
      id: "Históricodefaturas", 
      label: nls.menus.historicoFaturas,  
      go: "/meusprojetos/:IdProjeto", 
      icon: <IcoList />
    }]:[],
    { 
      id: "Suporte", 
      label: nls.menus.suporte, 
      action: "contatoSuporteModal", 
      icon: <IcoEnvelope /> 
    },
    { 
      id: "Sair", 
      label: nls.menus.sair, 
      icon: <IcoLogOut /> 
    },
  ]
}