import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './projetos.scss';
import { useEffect } from 'react';
import { Checkbox, LoadingContent, RadioButton } from '../../components';
import { listarPaises, listarSegmentos } from '../../layout/redux/layoutActions';
import { setProjetosFiltro } from './redux/projetosActions';
import { idiomaCustom } from '../../helpers';


export default () => {
  const dispatch = useDispatch()
  const { sistema, listas } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const { filtros, filtro } = useSelector(state=> state.projetosState)

    useEffect(()=> {
      dispatch([
        // listarSegmentos(),
        // listarPaises()
      ])
    }, [])

  const handleActive = e => {
    console.warn(e, 'handleActive');
 
    dispatch(setProjetosFiltro(e))
  }

  const paisesLista = [
    {idPais: nls.todos, nomePais: nls.todos}, 
    ...listas?.paisesIdiomas[idiomaCustom(sistema.idioma)]?listas?.paisesIdiomas[idiomaCustom(sistema.idioma)]:[]
  ]

  console.log({filtros, filtro}, 'projetos');
  return (
    <div className='box-banco-projetos-filtro'>
      <LoadingContent
        show={false}
      >
        <div>
          <BoxFiltro 
            name='segmentos' 
            label={nls.segmento} 
            data={listas.segmentosIdiomas[sistema.idioma === 'en-US' ? 'en': sistema.idioma.toLowerCase()]} 
            filtrado={filtro.segmentos} 
            multi={true} 
            action={(e)=> handleActive(e)}
            optionLabel='nome'
            optionValue='id' 
          />
        </div>
        <div>
          <BoxFiltro 
            name={'paises'} 
            label={nls.pais}
            data={paisesLista} 
            filtrado={filtro.paises} 
            action={(e)=> handleActive(e)} 
            optionLabel='nomePais'
            optionValue='idPais' 
          />
        </div>
      </LoadingContent>
    </div>
  )
}


const BoxFiltro = ({
  name, 
  label, 
  data, 
  filtrado, 
  multi=false, 
  optionLabel='name',
  optionValue='id',
  action=()=> null
}) => {
  return <div className='box-filtro'>
    {
      multi? <>
        <Checkbox 
          name={name}
          label={label}
          options={data}
          checked={filtrado}
          optionLabel={optionLabel}
          optionValue={optionValue}
          action={(e, f)=> action(f)}
        />
      </>
      : <RadioButton
         name={name}
         label={label}
         options={data}
         checked={filtrado}
         optionLabel={optionLabel}
         optionValue={optionValue}
         action={(e)=> action(e)}
      />
    }
    
  </div>
}
