import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './projetos.scss';
import { useEffect } from 'react';
import { getProjeto, listarProjetos } from './redux/projetosActions';
import { Button, LoadingContent, MaskValorMoedaex } from '../../components';
import { iconeprojeto } from '../meusProjetos/redux/meusProjetos';
import { listarPaises, listarSegmentos, modalOpen } from '../../layout/redux/layoutActions';
import AdquirirModal from './adquirirModal';
import ProjetosFiltros from './projetosFiltros';
import { idiomaCustom } from '../../helpers';


export default () => {
  const dispatch = useDispatch()
  const { sistema } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const { projetos, paginate } = useSelector(state=> state.projetosState)

  useEffect(()=> {
    dispatch(listarProjetos())
    dispatch(listarSegmentos({idioma: idiomaCustom(sistema.idioma)}))
    dispatch(listarPaises({idioma: idiomaCustom(sistema.idioma)}))
  }, [])

  const handleCheckboxSelect = e => {
  }

  const handleVerMais = e =>{
    dispatch([
      getProjeto({...e, periodo: e.periodos[0]}),
      modalOpen('adquirirProjetoModal')
    ])
  }

  console.log({projetos}, 'projetos');
  return (
    <div className='box-banco-projetos'>
      <h2>{nls?.title}</h2>
      <div>
        <ProjetosFiltros />
        <div className='box-banco-projetos-content'>
          <LoadingContent
          show={false}
        >
          {projetos.map(e=> <BoxProjeto nls={nls} idioma={sistema.idioma} projeto={e} action={()=> handleVerMais(e)} key={e.id} />)}
        </LoadingContent>
        </div>
      </div>
      <AdquirirModal />
    </div>
  )
}

const BoxProjeto = ({
  nls,
  idioma,
  projeto,
  action=()=> null,
}) =>{

  return <div key={projeto.id} className='box-banco-projetos-projeto'>
    <div className='box-banco-projetos-projeto-topinfo'>
      <div className='box-banco-projetos-projeto-topinfo-info'>
        <div>{iconeprojeto(projeto.icone)}</div>
        <div>
          <label>{projeto.nome}</label>
          <small>{projeto.segmento}</small>
        </div>
      </div>
      <div>
        <label><small>{nls.valor} {projeto?.periodos[0].id}</small></label>
        <strong>{MaskValorMoedaex(projeto?.periodos[0].valor, idioma)}</strong>
      </div>
    </div>
    <div className='box-banco-projetos-projeto-content'>
      <p>{projeto.descricao}</p>
      <div className='colflex col2'>
        <div>
          <label>{nls.empresas}</label>
          <span>{nls.empresasCadastros.replace('{{nEmpresas}}', projeto.empresas.length)}</span>
        </div>
        <div>
          <label>{nls.pais}</label>
          <span>{projeto.pais.name}</span>
        </div>
      </div>
    </div>
    <div className='box-banco-projetos-projeto-btns'>
      <Button
        color='primary'
        variant='outlined'
        size='block'
        onClick={()=> action(projeto)}
      >{nls.btnAdquirirProjeto}</Button>
    </div>
  </div>
}
