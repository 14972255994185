import { numberAleatori } from "../../../helpers"
import api from "../../../helpers/api"
import { gerarLista, gerarListaEmpresas, gerarListaEstabelecimentos, gerarListaProdutos, paginateInit } from "./meusProjetos"

export const setListMeusProjetos = payload => ({
  type: 'LIST_MEUS_PROJETOS',
  payload
})

export const setListMeuProjeto = payload => ({
  type: 'SET_MEUS_PROJETOS',
  payload
})

export const putListMeuProjeto = payload => ({
  type: 'PUT_MEUS_PROJETOS',
  payload
})

export const setFormProjeto = payload => ({
  type: 'SET_FORM_MEUS_PROJETO',
  payload
})

export const setFormProjetoLista = payload => ({
  type: 'SET_FORM_MEUS_PROJETO_LIST',
  payload
})

export const cleanFormProjeto = payload => ({
  type: 'CLEAN_FORM_MEUS_PROJETO',
  payload
})

export const setErroProjeto = payload => ({
  type: 'SET_MEUS_PROJETO_ERRO',
  payload
})

export const listarMeusProjetos = e => {
  return dispatch => {
    // dispatch(setListMeusProjetos(gerarLista()))
    api.get(`/Projeto/ListaProjetos`)
    .then(result => {
      console.warn(result, 'listarMeusProjetos');
      dispatch(setListMeusProjetos(result.data))
    })
    .catch(err => {
        console.error(err, 'listarMeusProjetos');
    });
  }
}

export const listarMeusProjetosEmpresas = e => {

  return dispatch => {
    api.get(`/Empresa/ListaEmpresas?idProjeto=${e}`)
    .then(result => {
      console.warn(result, 'listarMeusProjetosEmpresas');
      dispatch([
        setFormProjetoLista({name: 'empresas', value: result.data.informacoesEmpresa}),
        setFormProjetoLista({name: 'empresasPaginate', value: {...paginateInit(), totalElements: result.data.quantidadeEmpresas}})
      ])
    })
    .catch(err => {
        console.error(err, 'listarMeusProjetos');
    });
  }
}



export const setListMeuProjetoTab = payload => ({
  type: 'SET_MEUS_PROJETOS_TAB',
  payload
})

export const putListMeuProjetoTab = payload => ({
  type: 'PUT_MEUS_PROJETOS_TAB',
  payload
})

export const removeListMeuProjetoTab = payload => ({
  type: 'REMOVE_MEUS_PROJETOS_TAB',
  payload
})

export const setMeusProjetosLoad = payload => ({
  type: 'SET_MEUS_PROJETO_LOAD',
  payload
})


export const listarMeusProjetosEstabelecimentos = e => {
  return dispatch => {

    api.get(`/Estabelecimento/ListaEstabelecimentos?idProjeto=${e}`)
    .then(result => {
      console.warn(result, 'listarMeusProjetos');
      dispatch([
        setFormProjetoLista({name: 'estabelecimentos', value: result.data.informacaoEstabelecimento}),
        setFormProjetoLista({name: 'estabelecimentosPaginate', value: {...paginateInit(), totalElements: result.data.numeroEstabelecimentosProjeto}})
      ])
    })
    .catch(err => {
        console.error(err, 'listarMeusProjetos');
    });
  }
}

export const ativarInativaprojeto = e => {
  console.warn(e, 'ativarInativaprojeto');
  const urlAcao = e.statusBolean?'Inativaprojeto':'Ativaprojeto'
  return dispatch => {
    api.put(`/Projeto/${urlAcao}?idProjeto=${e.idProjeto}`)
    .then(result => {
      console.warn(result, 'result ativarInativaprojeto');
      dispatch(putListMeuProjeto(e))
    })
    .catch(err => {
        console.error(err, 'erro ativarInativaprojeto');
    });
  }
}

export const listarMeusProjetosProdutos = e => {
return dispatch => {

    api.get(`/Produto/ListaProdutos?idProjeto=${e}`)
    .then(result => {
      console.warn(result, 'listarMeusProjetos');
      dispatch([
        setFormProjetoLista({name: 'produtos', value: gerarListaProdutos()}),
        setFormProjetoLista({name: 'produtosPaginate', value: paginateInit()})
      ])
    })
    .catch(err => {
        console.error(err, 'listarMeusProjetos');
    });
  }
}

// const meuProjeto = {gruposProdutos: []}

// export const adicionarMeusProjetosGrupo = e => {
//   console.log(e, 'adicionarMeusProjetosGrupo');
//   return dispatch => {
//     if (e.nome) {
//       dispatch(setFormProjetoLista({name: 'gruposProdutos', value: [...meuProjeto.gruposProdutos, {...e, id: numberAleatori()}]}))
//     }
//   }
// } 

// export const editarMeusProjetosGrupo = e => {
//   console.log(e, 'editarMeusProjetosGrupo');
//   return dispatch => {
//     if (e.nome) {
//       const list = meuProjeto.gruposProdutos.map(g=> Number(g.id) === Number(e.id) ? {...g, ...e} : g)
//       dispatch(setFormProjetoLista({name: 'gruposProdutos', value: list}))
//     }
//   }
// } 

// export const removerMeusProjetosGrupo = e => {
//   console.log(e, 'removerMeusProjetosGrupo');
//   return dispatch => {
//     const list = meuProjeto.gruposProdutos.filter(g=> Number(g.id) !== Number(e.id))
//     dispatch(setFormProjetoLista({name: 'gruposProdutos', value: list}))
//   } 
// }