import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modalClose, modalOpen } from '../../../../layout/redux/layoutActions'
import { Button, IcoAdd, IcoPaste, IcoUpload, Input, InputFile, Modal, Select } from '../../../../components'
import { history, numberAleatori } from '../../../../helpers'
import { listarMeusProjetosEmpresas, putListMeuProjetoTab, setListMeuProjetoTab } from '../../redux/meusProjetosActions'


export default ({data}) => {
  const dispatch = useDispatch()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const nls = require(`../../nls/${sistema.idioma}.json`)
  const [form, setForm] = useState({})

  useEffect(()=> {
    dispatch(listarMeusProjetosEmpresas())
  }, [])

  useEffect(()=> {
  }, [])


  const handlerCriar = e => {
    delete e.actions
    delete e.checkboxCustom
    if (data?.id) {
      dispatch(putListMeuProjetoTab({name: 'estabelecimentos', value: {...e, status: false, status: 'sucesso'}}))      
    } else {
      dispatch(setListMeuProjetoTab({name: 'estabelecimentos', value: {...e, id: numberAleatori(), status: 'procurando'}}))
    }
    handleCloseModal()
  }  

  const handleCloseModal = e => {
    dispatch(modalClose()) 
  }  
  const list = [
    {
      id:'kflsd',
      endereco:'R. Loureiro da Cruz 100 - Aclimação',
      info:'R. Loureiro da Cruz, 100 – Aclimação, São Paulo – SP',
    },
    {
      id:'55',
      endereco:'Av. João Batista 200 - Aclimação',
      info:'Av. João Batista, 200 – Aclimação, Campinas – SP',
    }
  ]
  
  console.log(form,data, 'çççççççç');
  return (
    <Modal
      title={nls.configurar?.verificarEstabelecimentoTitle}
      size='medium'
      open={statusModal === 'verificarEstabelecimentosMeuProjeto' ? true : false}
      close={() => dispatch(modalClose())}
      closeText={nls.criarProjeto?.btnFechar}
      actions={<>
        <Button
          color='primary'
          onClick={()=> handlerCriar(form)}
        >{nls.configurar?.btnSalvarEstabelecimento}</Button>
      </>}
    >
      <div className='modal-meuProjeto-estabelecimentos-verificar'>
        <p className='informe'>{nls.configurar?.informeClick}</p>
        <div>
          {list.map(e=> {
            return <div>
              <label>{e.endereco}</label>
              <span>{e.info}</span>
            </div>
          })}
        </div>
      </div>
    </Modal>
  )
}