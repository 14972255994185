import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modalClose, modalOpen } from '../../../../layout/redux/layoutActions'
import { Button, IcoAdd, IcoPaste, IcoUpload, Input, InputFile, Modal } from '../../../../components'
import { history, numberAleatori } from '../../../../helpers'
import { putListMeuProjetoTab, setListMeuProjetoTab } from '../../redux/meusProjetosActions'


export default ({data}) => {
  const dispatch = useDispatch()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const { meuProjeto } = useSelector(state => state.meusProjetosState)
  const nls = require(`../../nls/${sistema.idioma}.json`)
  const [erro, setErro] = useState({})  
  const [form, setForm] = useState({})

  useEffect(()=> {
    let formInit = { nome: '', logo: '' }
    listRS.map(e=>{
      formInit = {...formInit, [e.id]: ''}
    })
    setForm({...form, ...formInit, ...data})
  }, [data])

  const listRS = [
    {
      id: 'facebook',
      label: 'Facebook',
      ph: 'www.facebook.com',
    },
    {
      id: 'instagram',
      label: 'Instagram',
      ph: 'www.instagram.com',
    },
    {
      id: 'twitter',
      label: 'Twitter',
      ph: 'www.twitter.com',
    },
    {
      id: 'youtube',
      label: 'Youtube',
      ph: 'www.youtube.com',
    },
    {
      id: 'tiktok',
      label: 'Tiktok',
      ph: 'www.tiktok.com',
    }
  ]

  const handleChange = e => {
    console.log(e, 'handleChange');
    setForm({...form, [e.name]: e.value})
  } 

  const handleChangeFile = e => {
    console.log(e, 'handleChange');
    setForm({...form, [e.name]: e.files})
  } 

  const handlerCriar = e => {
    console.warn(e, 'handlerCriar');
    delete e.actions
    delete e.checkboxCustom
    if (data?.id) {
      dispatch(putListMeuProjetoTab({name: 'empresas', value: {...e, status: false}}))      
    } else {
      dispatch(setListMeuProjetoTab({name: 'empresas', value: {...e, id: numberAleatori(), status: false}}))
    }
    handleCloseModal()
  }  

  const handleCloseModal = e => {
    dispatch(modalClose()) 
  }  
  const handleColar = e => {
    console.log(e, 'handleColar');
    navigator.clipboard.readText().then((clipText) => {
      setForm({...form, [e]: clipText})
    });
  }  

  console.log(form,data, 'çççççççç');
  return (
    <Modal
      title={data?.id?nls.configurar?.editEmpresaTitle:nls.configurar?.addEmpresaTitle}
      size='medium'
      open={statusModal === 'criarEmpresaMeuProjeto' ? true : false}
      close={() => dispatch(modalClose())}
      closeText={nls.criarProjeto?.btnFechar}
      actions={<>
        {data?.id? <Button
          color='default'
          onClick={()=> handleCloseModal()}
        >{nls.configurar?.btnCancelar}</Button>
        :null}
        <Button
          color='primary'
          onClick={()=> handlerCriar(form)}
        >{nls.configurar?.btnFinalizarSalvar}</Button>
      </>}
    >
      <div className='modal-meuProjeto-empresas-add'>
        <h5>{nls.configurar?.subTitleDetalhes}</h5>
        <div>
          <Input
            name='nome'
            label={nls.configurar?.formNome}
            value={form?.nome}
            placeholder={nls.configurar?.formNomePH}
            action={(e) => handleChange(e)}
            right={<>
              <span className='btn-colar' onClick={()=> handleColar('nome')}><IcoPaste /> {nls.configurar?.btnColar}</span>
            </>}
            required={{
              erro,
              message: ''
            }}
          />
          <InputFile
            name='logo'
            label={nls.configurar?.formLogo}
            value={form?.logo}
            placeholder={<span>{nls.configurar?.formLogoPH} <IcoUpload /></span> }
            action={(e) => handleChangeFile(e)}
          />
        </div>
        <h5>{nls.configurar?.subTitleRedesSociais}</h5>
        <div className='colflex col2'>
          {listRS.map(e=>{
            return <Input
              key={e.id}
              name={e.id}
              label={e.label}
              value={form?.[e.id]}
              placeholder={e.ph}
              action={(e) => handleChange(e)}
              right={<>
                <span className='btn-colar' onClick={()=> handleColar(e.id)}><IcoPaste /> {nls.configurar?.btnColar}</span>
              </>}
            />
          })}
        </div>
      </div>
    </Modal>
  )
}
