import { PaginateTotal } from '../../../components'

const paginateInit = {
  pageNumber: 1, 
  totalPages: 1, 
  totalElements: 0 
}

const initialState = {
  projetos: [],
  paginate: paginateInit,
  projeto: {
    nome: '',
    icone: '',
    segmento: '',
    descricao: '',
    empresas: [],
    pais: {},
    periodos: [],
    periodo: {},
  },
  load: {},
  erro: {},

  filtros: [],
  filtro: {
    segmentos: [],
    paises: {},
  },
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_PROJETOS':
      return { ...state, projetos: payload }
    case 'SET_PROJETOS_PAGINATE':
      return { ...state, paginate: payload }
    case 'CLEAN_PROJETOS_PAGINATE':
      return { ...state, paginate: paginateInit }
    case 'POST_PROJETO':
      return { ...state, projetos: [payload, ...state.projetos] }
    case 'PUT_PROJETO':
      return { ...state, projetos: state.projetos.map(e=> String(e.id) === String(payload.id)? {...e, ...payload} : e) }
    case 'DELETE_PROJETO':
      return { ...state, projetos: state.projetos.filter(e=> String(e.id) !== String(payload)) }
      
    case 'GET_PROJETO':
      console.log('XXPROJETOS-Previous State:', state, 'Action:', type, 'Novo State: ', payload);
      return { ...state, projeto: payload }
    case 'SET_PROJETO':
      console.log('XXPROJETOS-Previous State:', state, 'Action:', type, 'payload name: ', payload.name, 'payloadvalue:',payload.value);
      return { ...state, projeto: {...state.projeto, [payload.name]: payload.value} }
    case 'CLEAN_PROJETO':
      console.log('XXPROJETOS-Previous State:', state, 'Action:', type, 'Novo State: ', initialState.projeto);
      return { ...state, projeto: initialState.projeto }

    case 'LOAD_PROJETOS':
      return { ...state, load: {...state.load, [payload.name]: payload.value} }
    case 'ERROR_PROJETOS':
      return { ...state, erro: payload }

    case 'GET_PROJETOS_FILTROS':
      return { ...state, filtros: payload }
    case 'GET_PROJETOS_FILTRO':
      return { ...state, filtro: payload }
    case 'SET_PROJETOS_FILTRO':
      return { ...state, filtro: {...state.filtro, [payload.name]: payload.value} }
    default:
      return state
  }
}
