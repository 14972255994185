import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modalClose, modalOpen } from '../../../../layout/redux/layoutActions'
import { Button, IcoAdd, IcoHelpCircle, IcoPaste, IcoUpload, Input, InputFile, Modal, Popover, Select } from '../../../../components'
import { history, numberAleatori } from '../../../../helpers'
import { putListMeuProjetoTab, setListMeuProjetoTab, setMeusProjetosLoad } from '../../redux/meusProjetosActions'
import { GruposProdutos } from './grupos'


export default ({data}) => {
  const dispatch = useDispatch()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const { load, meuProjeto } = useSelector(state => state.meusProjetosState)
  const nls = require(`../../nls/${sistema.idioma}.json`)
  const [erro, setErro] = useState({})  
  const [form, setForm] = useState({})

  useEffect(()=> {
    let formInit = { nome: '', logo: '' }
    listRS.map(e=>{
      formInit = {...formInit, [e.id]: ''}
    })
    setForm({...form, ...formInit, ...data})
  }, [data])

  const listRS = [
    {
      id: 'facebook',
      label: 'Facebook',
      ph: 'www.facebook.com',
    },
    {
      id: 'instagram',
      label: 'Instagram',
      ph: 'www.instagram.com',
    },
    {
      id: 'twitter',
      label: 'Twitter',
      ph: 'www.twitter.com',
    },
    {
      id: 'youtube',
      label: 'Youtube',
      ph: 'www.youtube.com',
    },
    {
      id: 'tiktok',
      label: 'Tiktok',
      ph: 'www.tiktok.com',
    }
  ]

  const handleChange = e => {
    console.log(e, 'handleChange');
    setForm({...form, [e.name]: e.value})
  } 

  const handleChangeFile = e => {
    console.log(e, 'handleChange');
    setForm({...form, [e.name]: e.files})
  } 

  const handlerCriar = e => {
    delete e.actions
    delete e.checkboxCustom
    if (data?.id) {
      dispatch(putListMeuProjetoTab({name: 'produtos', value: {...e, status: false, status: 'sucesso'}}))      
    } else {
      dispatch(setListMeuProjetoTab({name: 'produtos', value: {...e, id: numberAleatori(), status: 'procurando'}}))
    }
    handleCloseModal()
  }  

  const handleCloseModal = e => {
    dispatch(modalClose()) 
  }  
  const handleColar = e => {
    console.log(e, 'handleColar');
    navigator.clipboard.readText().then((clipText) => {
      setForm({...form, [e]: clipText})
    });
  }  

  const handleAddForm = e => {
    console.log(e, 'handleAddForm');
    
    dispatch(setMeusProjetosLoad({name: 'produtosAddEdit', value: {...load.produtosAddEdit, add: !load.produtosAddEdit?.add}}))
  }  

  console.log(form,data, 'çççççççç');
  return (
    <Modal
      title={data?.id?nls.configurar?.editProdutoTitle:nls.configurar?.addProdutoTitle}
      size='medium'
      open={statusModal === 'criarProdutoMeuProjeto' ? true : false}
      close={() => dispatch(modalClose())}
      closeText={nls.criarProjeto?.btnFechar}
      actions={<>
        {data?.id? <Button
          color='default'
          onClick={()=> handleCloseModal()}
        >{nls.configurar?.btnCancelar}</Button>
        :null}
        <Button
          color='primary'
          onClick={()=> handlerCriar(form)}
        >{nls.configurar?.btnFinalizarSalvar}</Button>
      </>}
    >
      <div className='modal-meuProjeto-produtos-add'>
        <h5>{nls.configurar?.addEmpresaDetalhes}</h5>
        <div className='colflex col2'>
          <Input
            name='nome'
            label={nls.configurar?.formNome}
            value={form?.nome}
            placeholder={nls.configurar?.formNomePH}
            action={(e) => handleChange(e)}
            required={{
              erro,
              message: ''
            }}
          />
          <div className='colflex justify'>
            <div className='colflex-100'>
              <Select
                name='grupo'
                label={<>{nls.configurar?.formGrupo} <Popover>Esse e o grupo de produto</Popover></>}
                options={meuProjeto.gruposProdutos}
                selected={form?.grupo?form?.grupo:{}}
                action={(e) => handleChange(e)}
                textCustom={nls.select}
                optionLabel='nome'
                optionValue='id'
                required={{
                  erro,
                  message: ''
                }}
              />
            </div>
            <div>
              <GruposProdutos
                show={load?.produtosAddEdit?.add}
                id={'add'}
                actionOpen={
                  <Button
                    color='default circle'
                    onClick={()=> handleAddForm()}
                  ><IcoAdd /></Button>
                }
              />
            </div>
          </div>
        </div>
        <h5>{nls.configurar?.addEmpresaRedesSociais}</h5>
        <div className='colflex'>
          <Input
            name='urlAmazon'
            label={nls.configurar?.formAmazon}
            value={form?.urlAmazon}
            placeholder={nls.configurar?.formAmazonPH}
            action={(e) => handleChange(e)}
            right={<>
              <span className='btn-colar' onClick={()=> handleColar('urlAmazon')}><IcoPaste /> {nls.configurar?.btnColar}</span>
            </>}
            required={{
              erro,
              message: ''
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
