import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './centralAjuda.scss';
import { useEffect } from 'react';
import { Button, IcoArrowRigth, IcoClose, IcoEnvelope, ModalRight } from '../../components';
import { listarCentralAjuda, setCloseCentralAjuda } from './redux/centralAjudaActions';
import { modalOpen } from '../../layout/redux/layoutActions';
import ContatoSuporte from './contatoSuporte';


export default () => {
  const dispatch = useDispatch()
  const { sistema } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const { centralAjuda, open } = useSelector(state=> state.centralAjudaState)

  useEffect(()=> {
    dispatch(listarCentralAjuda())
  }, [])

  const handleClose = e => {
    dispatch(setCloseCentralAjuda())
  }

  const handleOpenSuporte = e => {
    dispatch(modalOpen('contatoSuporteModal'))
    handleClose()
  }

  console.log({centralAjuda}, 'usuarios');
  return (<>
    <ModalRight
      open={open}
    >
      <div className='box-central-ajuda'>
        <div>
          <div className='box-central-ajuda-title'>
            <h4>{nls.title}</h4>
            <div>
              <Button
                type='link'
                onClick={() => handleClose()}
              ><IcoClose /></Button>
            </div>
          </div>

          <div className='box-central-ajuda-content'>
              {centralAjuda.map(e=> {
                return <div key={e.id} className='box-central-ajuda-content-info'>
                  <h6>{e.titulo}</h6>
                  <p>{e.conteudo}</p>
                  <Button
                    type='link'
                    color='primary'
                  >{e.action} <IcoArrowRigth /> </Button>
                </div>
              })}
          </div>
        </div>
        <div className='box-central-ajuda-btns'>
          <Button
            type='link'
            onClick={()=> handleOpenSuporte()}
          ><IcoEnvelope /> {nls.btnEntreContato}</Button>
        </div>
      </div>
    </ModalRight>
    <ContatoSuporte />
  </>)
}
