import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modalClose, modalOpen } from '../../../../layout/redux/layoutActions'
import { Button, IcoAdd, IcoPaste, IcoUpload, Input, InputFile, Modal, Select } from '../../../../components'
import { history, numberAleatori } from '../../../../helpers'
import { listarMeusProjetosEmpresas, putListMeuProjetoTab, setListMeuProjetoTab, setMeusProjetosLoad } from '../../redux/meusProjetosActions'
import { GruposEstabelecimentos } from './grupos'


export default ({data}) => {
  const dispatch = useDispatch()
  const { listas, sistema, statusModal } = useSelector(state => state.layoutState)
  const { load, meuProjeto } = useSelector(state => state.meusProjetosState)
  const nls = require(`../../nls/${sistema.idioma}.json`)
  const [erro, setErro] = useState({})  
  const [form, setForm] = useState({})

  useEffect(()=> {
    dispatch(listarMeusProjetosEmpresas())
  }, [])

  useEffect(()=> {
    let formInit = { 
      nome: '', 
      empresa: {}, 
      grupo: {}, 
      rua: '', 
      bairro: '', 
      cidade: '', 
      estado: '', 
      cep: '', 
      pais: {}, 
      tripAdvisor: '', 
    }
    setForm({...formInit, ...form, ...data})
  }, [data])

  const handleChange = e => {
    console.log(e, 'handleChange');
    setForm({...form, [e.name]: e.value})
  } 

  const handleChangeFile = e => {
    console.log(e, 'handleChange');
    setForm({...form, [e.name]: e.files})
  } 

  const handlerCriar = e => {
    delete e.actions
    delete e.checkboxCustom
    if (data?.id) {
      dispatch(putListMeuProjetoTab({name: 'estabelecimentos', value: {...e, status: false, status: 'sucesso'}}))      
    } else {
      dispatch(setListMeuProjetoTab({name: 'estabelecimentos', value: {...e, id: numberAleatori(), status: 'procurando'}}))
    }
    handleCloseModal()
  }  

  const handleCloseModal = e => {
    dispatch(modalClose()) 
  }  

  const handleColar = e => {
    console.log(e, 'handleColar');
    navigator.clipboard.readText().then((clipText) => {
      setForm({...form, [e]: clipText})
    });
  }  

  const handleAddForm = e => {
    console.log(e, 'handleAddForm');
    
    dispatch(setMeusProjetosLoad({name: 'estabelecimentosAddEdit', value: {...load.estabelecimentosAddEdit, add: !load.estabelecimentosAddEdit?.add}}))
  }  
  
  console.log(form,data, 'çççççççç');
  return (
    <Modal
      title={data?.id?nls.configurar?.editEstabelecimentoTitle:nls.configurar?.addEstabelecimentoTitle}
      size='medium'
      open={statusModal === 'criarEstabelecimentosMeuProjeto' ? true : false}
      close={() => dispatch(modalClose())}
      closeText={nls.criarProjeto?.btnFechar}
      actions={<>
        {data?.id? <Button
          color='default'
          onClick={()=> handleCloseModal()}
        >{nls.configurar?.btnCancelar}</Button>
        :null}
        <Button
          color='primary'
          onClick={()=> handlerCriar(form)}
        >{nls.configurar?.btnFinalizarSalvar}</Button>
      </>}
    >
      <div className='modal-meuProjeto-estabelecimentos-add'>
        <h5>{nls.configurar?.subTitleDetalhes}</h5>
        <div className='colflex col2'>
          <Input
            name='nome'
            label={nls.configurar?.formNome}
            value={form?.nome}
            placeholder={nls.configurar?.formNomePH}
            action={(e) => handleChange(e)}
            required={{
              erro,
              message: ''
            }}
          />
          <Select
            name='empresa'
            label={nls.configurar?.formEmpresa}
            options={meuProjeto.empresas}
            selected={form?.empresa?form?.empresa:{}}
            action={(e) => handleChange(e)}
            textCustom={nls.select}
            optionLabel='nome'
            optionValue='id'
            required={{
              erro,
              message: ''
            }}
          />
        </div>
        <div className='colflex justify'>
          <div className='colflex-100'>
            <Select
              name='grupo'
              label={nls.configurar?.formGrupo}
              options={meuProjeto.gruposEstabelecimentos}
              selected={form?.grupo?form?.grupo:{}}
              action={(e) => handleChange(e)}
              textCustom={nls.select}
              optionLabel='nome'
              optionValue='id'
              required={{
                erro,
                message: ''
              }}
            />
          </div>
          <div>
            <GruposEstabelecimentos
              show={load?.estabelecimentosAddEdit?.add}
              id={'add'}
              actionOpen={
                <Button
                  color='default circle'
                  onClick={()=> handleAddForm()}
                ><IcoAdd /></Button>
              }
            />
          </div>
        </div>
        <h5>{nls.configurar?.subTitleEndereco}</h5>
        <div className='colflex col2'>
          <Input
            name='rua'
            label={nls.configurar?.formRua}
            value={form?.rua}
            placeholder={nls.configurar?.formRuaPH}
            action={(e) => handleChange(e)}
            required={{
              erro,
              message: ''
            }}
          />
          <Input
            name='bairro'
            label={nls.configurar?.formBairro}
            value={form?.bairro}
            placeholder={nls.configurar?.formBairroPH}
            action={(e) => handleChange(e)}
            required={{
              erro,
              message: ''
            }}
          />

          <Input
            name='cidade'
            label={nls.configurar?.formCidade}
            value={form?.cidade}
            placeholder={nls.configurar?.formCidadePH}
            action={(e) => handleChange(e)}
            required={{
              erro,
              message: ''
            }}
          />
          <Input
            name='estado'
            label={nls.configurar?.formEstado}
            value={form?.estado}
            placeholder={nls.configurar?.formEstadoPH}
            action={(e) => handleChange(e)}
            required={{
              erro,
              message: ''
            }}
          />

          <Input
            name='cep'
            label={nls.configurar?.formCep}
            value={form?.cep}
            placeholder={nls.configurar?.formCepPH}
            action={(e) => handleChange(e)}
            required={{
              erro,
              message: ''
            }}
          />
          <Select
            name='pais'
            label={nls.configurar?.formPais}
            options={listas.paisesIdiomas[sistema.idioma.toLowerCase()]}
            selected={form?.pais?form?.pais:{}}
            action={(e) => handleChange(e)}
            textCustom={nls.select}
            optionLabel='name'
            optionValue='id'
            required={{
              erro,
              message: ''
            }}
          />
        </div>
        <h5>{nls.configurar?.subTitleRedesSociais}</h5>
        <div>
          <Input
            name='tripAdvisor'
            label={nls.configurar?.formTripAdvisor}
            value={form?.tripAdvisor}
            placeholder={nls.configurar?.formTripAdvisorPH}
            action={(e) => handleChange(e)}
            right={<>
              <span className='btn-colar' onClick={()=> handleColar('tripAdvisor')}><IcoPaste /> {nls.configurar?.btnColar}</span>
            </>}
          />
        </div>
      </div>
    </Modal>
  )
}