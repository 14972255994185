import React, { HTMLAttributes, ReactChildren } from 'react';
import { validarCampo } from '../../validation/Validation';
import { IcoImage } from '../../icon/icon';
import { useState } from 'react';
import { useEffect } from 'react';

export const InputFile = ({
  action = () => null,
  actionBlur = () => null,
  type = 'text',
  color = '',
  required,
  placeholder = '',
  label,
  name = "",
  value,
  ...props
}) => {
  const [image, setImage] = useState({name: '', filesUrl: ''})

  useEffect(()=> {
    readURL(value)
  }, [value])

  if (required){
    const require = Object.keys(required);
  }

  const pattern = (e) => {
    if (typeof required?.pattern === 'object') {
      return JSON.stringify(required.pattern);
    } else if (typeof required?.pattern === 'string') {
      return required.pattern;
    } else {
      return '';
    }
  };

  const validar = (e) => {
    const v = require.length ? validarCampo(e) : {};
    action(e.target, v);
  };

  const validarOnblur = (e) => {
    const v = require.length ? validarCampo(e) : {};
    if (actionBlur) actionBlur(e.target, v);
  };
  
  const readURL = (input) => {
    if (input && typeof input === 'object' && input[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          setImage({...image, name: input[0].name , filesUrl: e.target.result})
        };
        reader.readAsDataURL(input[0]);
    } else {
      setImage({...image, name: '' , filesUrl: ''})
    }
  }

  return (
    <div
      className={`form-box form-inputfile ${color} ${
        required && (required.erro[name] ? 'erro' : '')
      } `}
      {...props.key?{key: props.key}:{}}
    >
      {label ? (
        <label className="label-input" htmlFor={`id-${name}`}>
          {required && require.length ? <span className='required-label'>*</span> : ''} 
          <span className='input-label-span-text'>{label}</span>
        </label>
      ) : null}

      <div className={`inputfile-${name}-wrapper inputfile-wrapper`}>
        {props.left ? <div className="input-actions-left">{props.left}</div> : null}
        <div className='inputfile'>
          <div>
            <div className='inputfile-arcuive'>
              {image.filesUrl?<img src={image.filesUrl} />:<IcoImage />}
            </div>
            {image.name?image.name:placeholder?placeholder:''}
          </div>
          <input
            {...props}
            id={`id-${name}`}
            type={'file'}
            name={name}
            file={value}
            onChange={(e) => validar(e)}
            onBlur={(e) => validarOnblur(e)}
            pattern={pattern('')}
            placeholder={placeholder}
          />
        </div>
        {props.right ? <div className="input-actions">{props.right}</div> : null}
      </div>
      {required && required?.erro?.[name] ? (
        <span className="campo-obrigatorio">
          {required.message?.[name] && required.erro[name]?
          required.message[name][required.erro[name]]
          :required.message }
        </span>
      ) : null}
    </div>
  );
};
