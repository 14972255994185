import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modalClose, modalOpen } from '../../../../layout/redux/layoutActions'
import { Button, IcoAdd, IcoHelpCircle, IcoImportar, IcoPaste, IcoUpload, Input, InputFile, Modal } from '../../../../components'
import { history, numberAleatori } from '../../../../helpers'
import { setFormProjeto, setListMeuProjeto } from '../../redux/meusProjetosActions'


export default ({data}) => {
  const dispatch = useDispatch()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const { meuProjeto } = useSelector(state => state.meusProjetosState)
  const nls = require(`../../nls/${sistema.idioma}.json`)
  const [erro, setErro] = useState({})  
  const [form, setForm] = useState({})

  useEffect(()=> {
    let formInit = { nome: '', logo: '' }
    listRS.map(e=>{
      formInit = {...formInit, [e.id]: ''}
    })
    setForm({...form, ...formInit, ...data})
  }, [data])

  const listRS = [
    {
      id: 'facebook',
      label: 'Facebook',
      ph: 'www.facebook.com',
    },
    {
      id: 'instagram',
      label: 'Instagram',
      ph: 'www.instagram.com',
    },
    {
      id: 'twitter',
      label: 'Twitter',
      ph: 'www.twitter.com',
    },
    {
      id: 'youtube',
      label: 'Youtube',
      ph: 'www.youtube.com',
    },
    {
      id: 'tiktok',
      label: 'Tiktok',
      ph: 'www.tiktok.com',
    }
  ]

  const handleChange = e => {
    console.log(e, 'handleChange');
    setForm({...form, [e.name]: e.value})
  } 

  const handleChangeFile = e => {
    console.log(e, 'handleChange');
    setForm({...form, [e.name]: e.files})
  } 

  const handlerCriar = e => {
    dispatch(setListMeuProjeto({...e, id: numberAleatori(), status: true, licencaData: new Date().toISOString()}))
  }  

  const handleCloseModal = e => {
    dispatch(modalClose()) 
  } 

  const validar = e => {
    console.log(e, 'handleColar');
    // navigator.clipboard.readText().then((clipText) => {
    //   setForm({...form, [e]: clipText})
    // });
    readURL(e.target.files)
  }  
  
  const readURL = (input) => {
    if (input && typeof input === 'object' && input[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          const valUrl = e.target.result.includes("/pdf")
          console.log(e, valUrl);
          setForm({...form, image: {name: input[0].name , filesUrl: valUrl?'':e.target.result}})
        };
        reader.readAsDataURL(input[0]);
    } else {
      setForm({...form, image: {name: '' , filesUrl: ''}})
    }
  }

  console.log(form,data, 'çççççççç');
  return (
    <Modal
      title={<span>{nls.configurar?.importEstabelecimentoTitle} <small><IcoHelpCircle /></small> </span>}
      size='medium'
      open={statusModal === 'importarEstabelecimentoMeuProjeto' ? true : false}
      close={() => dispatch(modalClose())}
      closeText={nls.criarProjeto?.btnFechar}
    >
      <div className='modal-meuProjeto-importar'>
        <div className='filearchive'>
            <input
              type={'file'}
              name={'importarestabelecimento'}
              file={''}
              onChange={(e) => validar(e)}
            />
          <div>
            {form.image?.filesUrl?<img src={form.image.filesUrl} />:<IcoImportar />}
            <span>{form.image?.name?form.image?.name:''}</span>
            <Button
              color='primary'
              onClick={()=> handlerCriar(meuProjeto.projeto)}
            ><IcoUpload /> {nls.configurar?.btnImportarArquivo}</Button>
          </div>
        </div>
        <div>
          <Button
            type='link'
            color='info'
            onClick={()=> handlerCriar(meuProjeto.projeto)}
          >{nls.configurar?.btnImportarModelo}</Button>
        </div>
        </div>
    </Modal>
  )
}
