import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modalClose } from '../../layout/redux/layoutActions'
import { Button, MaskValorMoedaex, Modal } from '../../components'
import { cleanProjeto, criarProjeto, editarProjeto, putProjeto, setProjeto } from './redux/projetosActions'
import { iconeprojeto } from '../meusProjetos/redux/meusProjetos'


export default () => {
  const dispatch = useDispatch()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const { projeto } = useSelector(state => state.projetosState)
  const nls = require(`./nls/${sistema.idioma}.json`)

  useEffect(()=> {

  }, [])

  const handlerCriar = e => {
    delete e.actions
    delete e.checkboxCustom

    handleCloseModal()
  }  

  const handleCloseModal = e => {
    dispatch([
      cleanProjeto(),
      modalClose()
    ]) 
  }  

  console.log(projeto, 'projetoprojetoprojeto');
  return (
    <Modal
      name={'modalprojeto'}
      title={<>
      <div className='box-banco-projetos-projeto-topinfo-info'>
        <div>{projeto.icone?iconeprojeto(projeto.icone):null}</div>
        <div>
          <label>{projeto.nome}</label>
          <small>{projeto.segmento}</small>
        </div>
      </div>
      </>}
      size='medium'
      open={statusModal === 'adquirirProjetoModal' ? true : false}
      close={() => handleCloseModal()}
      closeText={nls?.btnFechar}
      actions={<>
        <div className='modalprojeto-valores'>
          {projeto.periodo?.valorDesconto? <small className='valorvelho'>{`${MaskValorMoedaex(projeto.periodo?.valorDesconto, sistema.idioma)}${projeto.periodo?.periodoDesconto?`/${projeto.periodo?.periodoDesconto}`:''}`}</small> :null}
          <div>
            <h4>{MaskValorMoedaex(projeto.periodo?.valor?projeto.periodo?.valor:0, sistema.idioma)}/{projeto.periodo?.periodo}</h4>
            {projeto.periodo?.desconto?<span className='desconto'>{projeto.periodo?.desconto}</span>:null}
          </div>
        </div>
        <Button
          color='primary'
          onClick={()=> handlerCriar(projeto)}
        >{nls?.btnAdquirirProjeto}</Button>
      </>}
    >
        <div>
          <div>
            <label>{nls.descricao}</label>
            <p>{projeto.descricao}</p>
          </div>
          <div>
            <label>{nls.empresas}</label>
            <p className='modalprojeto-empresas-virgula'>{projeto.empresas?.map(e=> <span>{e}</span>)}</p>
          </div>
          <div>
            <label>{nls.pais}</label>
            <p>{projeto.pais?.name}</p>
          </div>
          <div className='modalprojeto-periodos'>
            <label>{nls.periodo}</label>
            <div>
              {projeto.periodos.map(e=> {
                if (projeto.periodo?.id === e.id) {
                  return <Button color='primary circle' onClick={()=> dispatch(setProjeto({name: 'periodo', value: e}))}>{e.id}</Button>
                }
                return <Button type='link' onClick={()=> dispatch(setProjeto({name: 'periodo', value: e}))}>{e.id}</Button>
              })}
            </div>
          </div>
        </div>
    </Modal>
  )
}
