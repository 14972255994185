import axios from 'axios';
import api from '../../../helpers/api';
import { getSistema, loaded, loading, logUserNew, modalClose, setCarregarUsuarioLogado } from '../../../layout/redux/layoutActions';
import { idiomaString } from '../../../helpers';

export const getInfoMeusDados = payload => ({
  type: 'GET_MEUS_DADOS',
  payload
})

export const setInfoMeusDados = payload => ({
  type: 'SET_MEUS_DADOS',
  payload
})

export const cleanInfoMeusDados = payload => ({
  type: 'CLEAN_MEUS_DADOS',
  payload
})

export const setMeusDadosErro = payload => ({
  type: 'SET_MEUS_DADOS_ERROR',
  payload
})

export const listarMeusDados = e => {
  return dispatch => {
    dispatch(loading())
    api.get(`/Usuario/InformaMeusDadosCadastro`)
    .then(result => {
      dispatch([
        getInfoMeusDados(result.data),
        loaded()
      ])
    })
    .catch(err => {
        console.error(err, 'salvarMeusDados err');
        dispatch(loaded())
    });
  }
}


export const salvarMeusDados = e => {
  console.warn(e, 'salvarMeusDados');
  const params = {
    // userId: e.id,
    nome: e.nome,
    sobrenome: e.sobrenome,
    celular: e.celular,
    idiomaId: String(e.idioma.idioma?e.idioma.idioma:e.idioma.nome)
  }
  return dispatch => {
    api.put(`Usuario/EditaMeusDadosCadastro`, params)
    .then(result => {
      const user = JSON.parse(localStorage.userINSV)
      localStorage.setItem('userINSV', JSON.stringify({...user, ...result.data, permissoes: user.permissoes}))
      dispatch([
        setCarregarUsuarioLogado({...user, ...result.data, permissoes: user.permissoes}),
        logUserNew({...user, ...result.data, permissoes: user.permissoes}),
        getSistema({name: 'idioma', value: idiomaString(result.data.idioma.nome)}),
        modalClose()
      ])
    })
    .catch(err => {
        console.error(err, 'salvarMeusDados err');
    });
  }
}

