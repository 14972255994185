import React, { useState } from 'react'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import CriarContent from '../../criar/criarContent';
import { Button, Checkbox, IcoAdd, IcoBin, IcoPencil, IcoUpload, List, LoadingContent } from '../../../../components';
import { useEffect } from 'react';
import { listarMeusProjetosEmpresas, removeListMeuProjetoTab } from '../../redux/meusProjetosActions';
import { modalOpen } from '../../../../layout/redux/layoutActions';
import Add from './add';
import Importar from './importar';
import { IF } from '../../../../helpers';


export default () => {
  const dispatch = useDispatch()
  const {idProjetos, idPasso} = useParams()
  const { sistema } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const { meuProjeto, paginate } = useSelector(state=> state.meusProjetosState)
  const [form, setForm] = useState({selects: {}})
  const [infomodal, setInfomodal] = useState({selects: {}})
    useEffect(()=> {
      if (idProjetos) {
        dispatch(listarMeusProjetosEmpresas(idProjetos))
      }
    }, [idProjetos])

  const headerDefault = [
    {
      column: 'checkboxCustom',
      text: <Checkbox 
        name='all'
        checked={form.selects?.all}
        action={e=> handleCheckboxSelect({name: 'all', value: e})}
      />,
      className: 'actions',
    },
    {
      column: 'nomeEmpresa',
      text: nls.table.nome,
      className: '',
    },
    {
      column: 'logo',
      text: nls.table.logo,
      className: '',
    },
    {
      column: 'urlFacebookEmpresa',
      text: 'Facebook',
      className: '',
    },
    {
      column: 'urlInstagramEmpresa',
      text: 'Instagram',
      className: '',
    },
    {
      column: 'urlTwitterEmpresa',
      text: 'Twitter',
      className: '',
    },
    {
      column: 'urlYoutubeEmpresa',
      text: 'Youtube',
      className: '',
    },
    {
      column: 'urlTiktokEmpresa',
      text: 'Tiktok',
      className: '',
    },
    {
      column: 'actions',
      text: '',
      className: 'actions',
    }
  ]

  const handleCheckboxSelect = e => {
    if (e.name === 'all') {
      let all = {all: e.value}
      meuProjeto.empresas.map(p=> {  all = {...all, [p.id]: e.value} })
      setForm({...form, selects: all})
    } else {
      setForm({...form, selects: {...form.selects, [e.name]: e.value}})
    }
  }

  const selectedHas = Object.keys(form.selects).filter(p=> form.selects[p] ).length

  const handlePaginate = e => {
    console.warn(e, 'handlePaginate');
  }

  const handlePerPage = e => {
    console.warn(e, 'handlePerPage');
  }
  
  const handleAddModal = e => {
    console.warn(e, 'handlePerPage');
    if (e) {
      setInfomodal(e)
    } else {
      setInfomodal({})
    }
    dispatch(modalOpen('criarEmpresaMeuProjeto'))
  }
  
  const handleRemove = e => {
    console.error(e, 'handleRemove');
    const returnedHas = Object.keys(e).filter(f=> f !== 'all' && e[f] === true)
    console.error(returnedHas, 'handleRemove');
    returnedHas.map(id=> {
      dispatch(removeListMeuProjetoTab({id, name: 'empresas'}))
    })
    handleCheckboxSelect({name: 'all', value: false})
  }

  const handleImportarModal = e => {
    console.warn(e, 'handlePerPage');
    dispatch(modalOpen('importarEmpresaMeuProjeto'))
  }
  
  console.log({idPasso}, 'idPassoidPasso');
  return (
    <div className='box-meus-projetos-empresas'>
      <div className='box-meus-projetos-topinfo'>
        <h5>{nls.title}</h5>
        <div>
          <Button
            type='link'
            color='default'
            onClick={()=> handleRemove(form.selects)}
            disabled={selectedHas === 0? true:false}
          ><IcoBin /> {nls.btnExcluir}</Button>
          <IF se={sistema?.creditoConta?.creditoEmpresas}>
            <Button
              color='default'
              onClick={()=> handleImportarModal()}
            ><IcoUpload /> {nls.btnImportarEmpresas}</Button>
            <Button
              color='primary circle'
              onClick={()=> handleAddModal()}
            ><IcoAdd /> {nls.btnAddEmpresa}</Button>
          </IF>
        </div>
      </div>
      <LoadingContent
          show={false}
        >
          <List
            header={headerDefault}
            data={meuProjeto.empresas}
            listCustom={e=> {
              const item = e
              item.checkboxCustom = <>
                <Checkbox 
                  name={e.id}
                  checked={form.selects?.[e.id]}
                  action={c=> handleCheckboxSelect({name: e.id, value: c})}
                />
              </>
              item.actions = e.status? <>
                <Button
                  type='link'
                  color='default'
                  onClick={()=> handleAddModal(e)}
                ><IcoPencil /> {nls.btnEditar}</Button>
              </> :null

              return item
            }}
            paginate={meuProjeto.empresasPaginate}
            actionPaginate={(e)=> handlePaginate(e)}
            handlePerPage={(e)=> handlePerPage(e)}
            nls={nls.paginacao}
          />
        </LoadingContent>
        <Add data={infomodal} />
        <Importar />
    </div>
  )
}
