import React, { useState } from 'react'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import CriarContent from '../../criar/criarContent';
import { Button, IcoSearch } from '../../../../components';


export default () => {
  const dispatch = useDispatch()
  const {idProjetos, idPasso} = useParams()
  const { sistema } = useSelector(state => state.layoutState)
  const nls = require(`../../nls/${sistema.idioma}.json`)
  const { listMeusProjetos, paginate } = useSelector(state=> state.meusProjetosState)

  console.log({idPasso}, 'idPassoidPasso');
  return (
    <div className='box-meus-projetos-configurar'>
      <div className='box-meus-projetos-topinfo'>
        <h5>{nls.configurar.titleProjeto}</h5>
      </div>
      <CriarContent />
      <div className='box-btns'>
        <Button
          color='default'
          onClick={()=> null}
        >{nls.configurar.btnDescartar}</Button>
        <Button
          color='primary'
          onClick={()=> null}
        >{nls.configurar.btnSalvar}</Button>
      </div>
    </div>
  )
}
