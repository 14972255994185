import React, { useState } from 'react'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import CriarContent from '../../criar/criarContent';
import { Button, Checkbox, IcoAdd, IcoBin, IcoPencil, IcoUpload, List, LoadingContent } from '../../../../components';
import { useEffect } from 'react';
import { listarMeusProjetosEstabelecimentos, removeListMeuProjetoTab } from '../../redux/meusProjetosActions';
import { modalOpen } from '../../../../layout/redux/layoutActions';
import Add from './add';
import Importar from './importar';
import Verificar from './verificar';
import Grupos from './grupos';
import { IF } from '../../../../helpers';


export default () => {
  const dispatch = useDispatch()
  const {idProjetos, idPasso} = useParams()
  const { sistema } = useSelector(state => state.layoutState)
  const nls = require(`../../nls/${sistema.idioma}.json`)
  const { meuProjeto, paginate } = useSelector(state=> state.meusProjetosState)
  const [form, setForm] = useState({selects: {}})
  const [infomodal, setInfomodal] = useState({selects: {}})
  
    useEffect(()=> {
      if (idProjetos) {
        dispatch(listarMeusProjetosEstabelecimentos(idProjetos))
      }
    }, [idProjetos])

  const headerDefault = [
    {
      column: 'checkboxCustom',
      text: <Checkbox 
        name='all'
        checked={form.selects?.all}
        action={e=> handleCheckboxSelect({name: 'all', value: e})}
      />,
      className: 'actions',
    },
    {
      column: 'nomeEstabelecimento',
      text: nls.configurar.tableNome,
      className: '',
    },
    {
      column: 'nomeEmpresa',
      text: 'Empresa',
      className: '',
    },
    {
      column: 'grupoEstabelecimento',
      text: 'Grupo',
      className: '',
    },
    {
      column: 'ruaAvenidaRodovia',
      text: 'Rua',
      className: '',
    },
    {
      column: 'bairro',
      text: 'Bairro',
      className: '',
    },
    {
      column: 'cidade',
      text: 'Cidade',
      className: '',
    },
    {
      column: 'estado',
      text: 'Estado',
      className: '',
    },
    {
      column: 'cep',
      text: 'CEP',
      className: '',
    },
    {
      column: 'país',
      text: 'País',
      className: '',
    },
    {
      column: 'latitudeLongitude',
      text: 'Latitude e longitude',
      className: '',
    },
    {
      column: 'statusCustom',
      text: 'Status verificação',
      className: '',
    },
    {
      column: 'actions',
      text: '',
      className: 'actions',
    }
  ]

  const handleCheckboxSelect = e => {
    if (e.name === 'all') {
      let all = {all: e.value}
      meuProjeto.estabelecimentos.map(p=> {  all = {...all, [p.id]: e.value} })
      setForm({...form, selects: all})
    } else {
      setForm({...form, selects: {...form.selects, [e.name]: e.value}})
    }
  }

  const selectedHas = Object.keys(form.selects).filter(p=> form.selects[p] ).length

  const handlePaginate = e => {
    console.warn(e, 'handlePaginate');
  }

  const handlePerPage = e => {
    console.warn(e, 'handlePerPage');
  }
  
  const handleAddModal = e => {
    console.warn(e, 'handlePerPage');
    if (e) {
      setInfomodal(e)
    } else {
      setInfomodal({})
    }
    dispatch(modalOpen('criarEstabelecimentosMeuProjeto'))
  }
  
  const handleRemove = e => {
    console.error(e, 'handleRemove');
    const returnedHas = Object.keys(e).filter(f=> f !== 'all' && e[f] === true)
    console.error(returnedHas, 'handleRemove');
    returnedHas.map(id=> {
      dispatch(removeListMeuProjetoTab({id, name: 'estabelecimentos'}))
    })
    handleCheckboxSelect({name: 'all', value: false})
  }

  const handleImportarModal = e => {
    console.warn(e, 'handleImportarModal');
    dispatch(modalOpen('importarEstabelecimentoMeuProjeto'))
  }

  const handleVerificarModal = e => {
    console.warn(e, 'handleVerificarModal');
    if (e) {
      setInfomodal(e)
    } else {
      setInfomodal({})
    }
    dispatch(modalOpen('verificarEstabelecimentosMeuProjeto'))
  }

  const statusTypes = e => {
    switch (e.status) {
      case 'procurando':
        return <span className='flag-warning'>Procurando estabelecimento</span>;
      case 'sucesso':
        return <span className='flag-info'>Verificação concluída</span>;
      default:
        return <span className='flag-primary' onClick={()=> handleVerificarModal(e)}>Realizar verificação</span>;
    }
  }

  const handleGruposModal = e => {
    console.warn(e, 'handleGruposModal');
    dispatch(modalOpen('gruposEstabelecimentoMeuProjeto'))
  }
  
  console.log({idPasso}, 'idPassoidPasso');
  return (
    <div className='box-meus-projetos-estabelecimentos'>
      <div className='box-meus-projetos-topinfo'>
        <h5>{nls.configurar.titleEstabelecimentosList}</h5>
        <div>
          <Button
            type='link'
            color='default'
            onClick={()=> handleRemove(form.selects)}
            disabled={selectedHas === 0? true:false}
          ><IcoBin /> {nls.configurar.btnExcluir}</Button>
          <Button
            color='primary'
            onClick={()=> handleGruposModal()}
          >{nls.configurar.btnGruposEstabelecimentos}</Button>
          <IF se={sistema?.creditoConta?.creditoEstabelecimentos}>
            <Button
              color='default'
              onClick={()=> handleImportarModal()}
            ><IcoUpload /> {nls.configurar.btnImportarEstabelecimentos}</Button>
            <Button
              color='primary circle'
              onClick={()=> handleAddModal()}
            ><IcoAdd /> {nls.configurar.btnAddEstabelecimento}</Button>
          </IF>
        </div>
      </div>
      <LoadingContent
        show={false}
      >
        <List
          header={headerDefault}
          data={meuProjeto.estabelecimentos}
          listCustom={e=> {
            const item = e
            item.latitudeLongitude = e.coordenadas
            item.checkboxCustom = <>
              <Checkbox 
                name={e.id}
                checked={form.selects?.[e.id]}
                action={c=> handleCheckboxSelect({name: e.id, value: c})}
              />
            </>
            item.statusCustom = statusTypes(e)
            item.actions = e.status? <>
              <Button
                type='link'
                color='default'
                onClick={()=> handleAddModal(e)}
              ><IcoPencil /> {nls.configurar.btnEditar}</Button>
            </> :null

            return item
          }}
          paginate={meuProjeto.estabelecimentosPaginate}
          actionPaginate={(e)=> handlePaginate(e)}
          handlePerPage={(e)=> handlePerPage(e)}
          nls={nls.paginacao}
        />
      </LoadingContent>
      <Add data={infomodal} />
      <Importar />
      <Verificar data={infomodal} />
      <Grupos />
    </div>
  )
}
