import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IcoAdd, IcoArrowRigth, IcoCalendar, IcoCog, IcoHelpCircle, IcoProjeto1, IcoProjeto2, IcoProjeto3, IcoProjeto4, List, LoadingContent, MaskCpf, MaskData, Popover, dateCustom } from '../../components'

import './meusProjetos.scss'
import { ativarInativaprojeto, listarMeusProjetos, putListMeuProjeto, searchMovie } from './redux/meusProjetosActions'
import { useEffect } from 'react'
import Criar from './criar/criar'
import { modalOpen } from '../../layout/redux/layoutActions'
import { iconeprojeto, iconesList } from './redux/meusProjetos'
import { IF, history } from '../../helpers'
import ObterMais from './obterMais'

export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const { listMeusProjetos, paginate } = useSelector(state=> state.meusProjetosState)

  useEffect(()=> {
    dispatch(listarMeusProjetos())
  }, [sistema.idioma])

  const headerDefault = [
    {
      column: 'projetoCuston',
      text: nls.listarProjetos.tableProjetos,
      className: '',
    },
    {
      column: 'tipo',
      text: nls.listarProjetos.tableTipo,
      sort: '',
      className: '',
    },
    {
      column: 'segmentoDoProjeto',
      text: nls.listarProjetos.tableSegmento,
      sort: '',
      className: '',
    },
    {
      column: 'statusCuston',
      text: nls.listarProjetos.tableStatus,
      sort: '',
      className: '',
    },
    {
      column: 'licencaDataCuston',
      text: nls.listarProjetos.tableLicenca,
      sort: '',
      className: '',
    },
    {
      column: 'actions',
      text: '',
      sort: '',
      className: 'actions onlysvg',
    }
  ]

  const handlePaginate = e => {
    console.warn(e, 'handlePaginate');
  }

  const handlePerPage = e => {
    console.warn(e, 'handlePerPage');
  }

  const handleConfigurar = e => {
    console.warn(e, 'handleConfigurar');
    history.push(`/meusprojetos/${e.idProjeto}/configurar`)
  }

  const handleVizualizar = e => {
    console.warn(e, 'handleVizualizar');
    history.push(`/resumoprojeto`)
  }

  const handleMudaStatus = e => {
    console.warn(e, 'handleMudaStatus');
    if (e.permissaoAlterarStatusProjeto) {
      dispatch(ativarInativaprojeto(e))
    }
  }

  const handleCriar = e => {
    console.warn(e, 'handleCriar');
    if (sistema?.creditoConta?.creditoProjetos) {
      dispatch(modalOpen('criarMeuProjetoTipo'))
    } else{
      dispatch(modalOpen('obter-mais-usuarios'))
    }
  }

  const typeStatusCustom = e => {
    switch (e) {
      case 'Ativo':
        return {class: 'flag-success', text: e, status: true};
      case 'Active':
        return {class: 'flag-success', text: e, status: true};
      case 'Activo':
        return {class: 'flag-success', text: e, status: true};
      default:
        return {class: 'flag-danger', text: e, status: false};
    }
  }
  
  return (
    <div className='box-meus-projetos'>
      <div className='box-content'>
        <div className='box-content-topinfo'>
          <h3>{nls.titleList} <Popover>{nls.mensagem.titlePopover}</Popover></h3>
          <div>
            <IF se={usuario?.permissoes?.CriarNovoProjeto}>
              <Button
                color='primary circle'
                onClick={()=> handleCriar()}
              ><IcoAdd /> {nls.criar}</Button>
            </IF>
          </div>
        </div>
        <LoadingContent
          show={false}
        >
          <List
            header={headerDefault}
            data={listMeusProjetos}
            listCustom={e=> {
              const item = e
              const data = dateCustom(e.licencaData)

              item.projetoCuston = <div className='info-projeto'>
                <div >{iconeprojeto(e.icone)}</div>
                <div>
                  <label>{e.descricao}</label>
                  <p>{e.descricaoLonga}</p>
                </div>
              </div>
              const statusInfo = typeStatusCustom(e.status)
              item.statusCuston = <span style={{cursor: 'pointer'}} className={statusInfo?.class} onClick={()=> handleMudaStatus({...e, statusBolean: statusInfo.status})}>{e.status}</span>
              // e.status?nls.ativo:nls.inativo
              item.licencaDataCuston = <span><IcoCalendar /> {MaskData(data.dataCriacao, '', sistema.idioma)}</span>
              item.actions = <div>
                <Button
                  type='link'
                  onClick={()=> handleConfigurar(e)}
                  disabled={!e.permissaoEditarProjeto}
                ><IcoCog /></Button>
                <Button
                  type='link'
                  color='primary'
                  onClick={()=> handleVizualizar(e)}
                ><IcoArrowRigth /></Button>
              </div>

              return item
            }}
            paginate={paginate}
            actionPaginate={(e)=> handlePaginate(e)}
            handlePerPage={(e)=> handlePerPage(e)}
            nls={nls.paginacao}
          />
        </LoadingContent>
      </div>
      <Criar />
      <ObterMais/>
    </div>
  )
}
