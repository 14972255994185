import React from 'react'

export const gerarLista = e => {
    return Array(4).fill().map((_, i)=> ({
        id: i+1,
        nome: `Projeto ${i+1}`,
        icone: `projeto${i+1}`,
        segmento: `segmento ${i+1}`,
        descricao: `descricao ${i+1}`,
        empresas: Array(4).fill().map((_, i)=> (`empresa ${i+1}`)),
        pais: {id: 'pt-BR', name: 'Brasil'},

        periodos: [
          {
            id:'Anual',
            valor:'432',
            periodo:'ano',
            desconto:'6% desconto de no plano anual',
          },
          {
            id:'Semestral',
            valor:'520',
            periodo:'semestre',
            desconto:'4% desconto de no plano semestral',
            valorDesconto:'700',
            periodoDesconto:'mes',
          },
          {
            id:'Trimestral',
            valor:'600',
            periodo:'trimestre',
            desconto:'1% desconto de no plano trimestral',
          },
          {
            id:'Mensal',
            valor:'700',
            periodo:'mensal',
            desconto:'',
          }
        ],

        status: true,
        licencaData: "2023-12-08T00:09:51.744Z"
    }))
}

export const gerarFiltros = e => {
  return [
    {
      id: 'segmento',
      nome: 'Segmento',
      itens: [
        'Tecnologia'
      ]
    }
  ]
}


export const paginateInit = e => {
  return { 
    pageNumber: 1, 
    totalPages: 1, 
    totalElements: 0,
    perPage: 7
  }
}
