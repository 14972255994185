import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, IcoEye, IcoEyeBlocked, Input, Loading, MaskTelefoneInt, SelectDefault, validacaoForm, validarCampo, verificaValidacao } from '../../components'

import { cadastrarUsuario, setChangeCadastro, setError, setSenhaVisivel } from './redux/loginActions'
import './login.scss'
import { history, idiomaString } from '../../helpers'
import imgFrame from '../../layout/img/login/frame1.jpg'
import imgLogo from '../../layout/img/logo-custom.png'
import { getIp, listarEstados, listarPaises, listarSegmentos } from '../../layout/redux/layoutActions'

export default () => {
  const dispatch = useDispatch()
  const { load, sistema, listas, logIn } = useSelector(state=> state.layoutState)
  const { senhaVisivel, cadastro, erro } = useSelector(state=> state.loginState)
  const nls = require(`./nls/${sistema.idioma}.json`)

  useEffect(()=> {
    if (!logIn) { 
      dispatch([
        getIp()
      ])
    }
  },[])

  useEffect(()=> {
    if (!listas.paises.length) {
      dispatch([
        // listarSegmentos(),
        // listarPaises()
      ])
    }
  }, [])

  const formRequired = {
    Nome: '',
    Sobrenome: '',
    Email: 'email',
    Celular: '',
    Pais: 'select',
    Segmento: 'select',
    Senha: '',
    SenhaConfirma: ''
  }

  const handleChange = e => {
    dispatch(setChangeCadastro(e))
    if (formRequired[e.name]) {
      const verify = validarCampo(e)
      dispatch(setError({...erro, [verify.name]: verify.message}))
    }

    if (e.name === 'pais') {
      dispatch(listarEstados(e.value))
    }
  }

  const handleSee = e => {
    dispatch(setSenhaVisivel(e))
  }

  const handleCriar = e => {
    const valid = validacaoForm({formValues: e, formRequired})
    dispatch(setError(valid))
    const verify = verificaValidacao(valid)
    if (e.Senha !== e.SenhaConfirma) {
      dispatch(setError({...erro, 'senhaConfirma': 'different'}))      
    }
    if (verify.value) {
      dispatch(cadastrarUsuario(e))
    }
  }

  const idiomaCustom = e => {
    e = e === 'en-US'?'en':e
    return e.toLowerCase()
  }

  return (
    <div className='box-login'>
      <Alert />
      <div className='box-login-container'>
        <div className='box-login-frame'>
          <img src={imgFrame} />
        </div>
        <div className='box-login-form'>
          <div className='box-login-content'>
            <div className='box-login-logo'>
              {/* <IcoLogo style={{width: '262px'}} /> */}
              <img src={imgLogo} />
            </div>
            <div className='box-login-info'>
              <h2>{nls?.cadastrar.title}</h2>
              <h4>{nls?.cadastrar.subtitle}</h4>
              <p></p>
            </div>
            <div className='colflex col2'>
              <Input
                label={nls?.formNome}
                name='Nome'
                action={(e) => handleChange(e)}
                value={cadastro.Nome}
                placeholder={nls?.formNomePH}
                required={{
                  erro,
                  message: nls.mensagem.obrigatorio,
                  pattern: formRequired.Nome
                }}
              />
              <Input
                label={nls?.formSobrenome}
                name='Sobrenome'
                action={(e) => handleChange(e)}
                value={cadastro.Sobrenome}
                placeholder={nls?.formSobrenomePH}
                required={{
                  erro,
                  message: nls.mensagem.obrigatorio,
                  pattern: formRequired.Sobrenome
                }}
              />
            </div>
            <div className='colflex col2'>
              <Input
                label={nls?.formEmail}
                name='Email'
                action={(e) => handleChange(e)}
                value={cadastro.Email}
                placeholder={nls?.formEmailPH}
                required={{
                  erro,
                  message: nls.mensagem,
                  pattern: formRequired.Email
                }}
              />
              <Input
                name='Celular'
                label={nls?.formCelular}
                value={MaskTelefoneInt(cadastro?.Celular)}
                placeholder={nls?.formCelularPH}
                action={(e) => handleChange(e)}
                maxLength='20'
                required={{
                  erro,
                  message: nls.mensagem.obrigatorio,
                  pattern: formRequired.Celular
                }}
              />
            </div>
            <hr />
            <div className='colflex col2'>
              <SelectDefault
                name='Pais'
                label={nls?.formPais}
                options={listas.paisesIdiomas[idiomaCustom(sistema.idioma)]}
                selected={cadastro?.Pais?cadastro?.Pais:''}
                action={(e) => handleChange(e)}
                textCustom={nls.select}
                optionLabel='nomePais'
                optionValue='idPais'
                required={{
                  erro,
                  message: nls.mensagem.obrigatorio,
                  pattern: formRequired.Pais
                }}
              />
              <SelectDefault
                name='Segmento'
                label={nls?.formSegmento}
                options={listas.segmentosIdiomas[idiomaCustom(sistema.idioma)]}
                selected={cadastro?.Segmento?cadastro?.Segmento:''}
                action={(e) => handleChange(e)}
                textCustom={nls.select}
                optionLabel='nome'
                optionValue='id'
                required={{
                  erro,
                  message: nls.mensagem.obrigatorio,
                  pattern: formRequired.Segmento
                }}
              />
            </div>
            <hr />
            <div className='colflex col2'>
              <Input
                label={nls?.formSenha}
                name='Senha'
                type={!senhaVisivel.Senha?'password':'text'}
                action={(e) => handleChange(e)}
                value={cadastro.Senha}
                placeholder={nls?.formSenhaPH}
                right={
                  <Button type='link' onClick={()=> handleSee({name: 'Senha', value: !senhaVisivel.senha})}>
                    {!senhaVisivel.Senha ? <IcoEye />
                    : <IcoEyeBlocked />}
                  </Button>
                }
                required={{
                  erro,
                  message: nls.mensagem.obrigatorio,
                  pattern: formRequired.Senha
                }}
              />
              <Input
                label={nls?.formSenhaConfirmar}
                name='SenhaConfirma'
                type={!senhaVisivel.SenhaConfirma?'password':'text'}
                action={(e) => handleChange(e)}
                value={cadastro.SenhaConfirma}
                placeholder={nls?.formSenhaConfirmarPH}
                right={
                  <Button type='link' onClick={()=> handleSee({name: 'SenhaConfirma', value: !senhaVisivel.confirmarsenha})}>
                    {!senhaVisivel.SenhaConfirma ? <IcoEye />
                    : <IcoEyeBlocked />}
                  </Button>
                }
                required={{
                  erro,
                  message: nls.mensagem,
                  pattern: formRequired.SenhaConfirma
                }}
              />
            </div>
            <div className='box-login-action'>
              <div className='box-login-action-btns'>
                <Button
                  color='primary'
                  variant='outlined'
                  onClick={()=> history.push('/login')}
                >{nls?.btnCancelar}</Button>
                <Button
                  color='primary'
                  onClick={()=> handleCriar(cadastro)}
                >{nls?.btnCriarConta}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {load? <Loading />:null}
    </div>
  )
}
