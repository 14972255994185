import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IcoProjeto1, IcoProjeto2, IcoProjeto3, IcoProjeto4, Input, SelectDefault, TextArea } from '../../../components'
import { setFormProjeto } from '../redux/meusProjetosActions'
import { iconesList } from '../redux/meusProjetos'
import { listarSegmentos } from '../../../layout/redux/layoutActions'


export default () => {
  const dispatch = useDispatch()
  const { sistema, listas } = useSelector(state => state.layoutState)
  const { meuProjeto } = useSelector(state => state.meusProjetosState)
  const nls = require(`../nls/${sistema.idioma}.json`)
  const [erro, setErro] = useState({})  
  const [form, setForm] = useState({
    nome: '',
    segmento: '',
  })

  useEffect(()=> {
    dispatch(listarSegmentos({idioma: idiomaCustom(sistema.idioma)}))
  }, [])

  const formRequired = {
    segmento: ''
  }

  const handleChange = e => {
    console.log(e, 'handleChange');
    dispatch(setFormProjeto({name: e.name, value: e.value, tipo: 'projeto'}))
  }

  const idiomaCustom = e => {
    e = e === 'en-US'?'en':e
    return e.toLowerCase()
  }

  console.warn(form, 'form');
  return (
    <div className='modal-criarMeuProjeto'>
      <div className='colflex col2'>
        <Input
          name='nome'
          label={nls.criarProjeto?.nomeProjeto}
          value={meuProjeto.projeto?.nome}
          placeholder={nls.criarProjeto?.nomeProjetoPlaceholder}
          action={(e) => handleChange(e)}
          required={{
            erro,
            message: ''
          }}
        />
        <SelectDefault
          name='segmento'
          label={nls.criarProjeto?.segmento}
          options={listas.segmentosIdiomas[idiomaCustom(sistema.idioma)]}
          selected={meuProjeto.projeto?.segmento?meuProjeto.projeto?.segmento:''}
          action={(e) => handleChange(e)}
          textCustom={nls.select}
          optionLabel='nome'
          optionValue='id'
          required={{
            erro,
            message: nls.mensagem.obrigatorio,
            pattern: formRequired.segmento
          }}
        />
      </div>
      <div className="form-box">
        <label className="label-input">
          <span className="input-label-span-text">{nls.criarProjeto?.iconeProjeto}</span>
        </label>
        <div className="form-icones">
          {iconesList.map(e=> {
            return <div key={`icone-${e.id}`} onClick={()=> handleChange({name: 'icone', value: e.id})} className={meuProjeto.projeto?.icone === e.id? 'active': ''}>
              <div className='iconeprojeto' style={{backgroundColor: e.color}}>{e.icon}</div>
            </div>
          })}
        </div>
      </div>
      <TextArea
        name='descricao'
        label={nls.criarProjeto?.descricao}
        value={meuProjeto.projeto?.descricao}
        placeholder={nls.criarProjeto?.descricaoPlaceholder}
        action={(e) => handleChange(e)}
      />
    </div>
  )
}
