import React, { useState } from 'react'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, IcoAdd, IcoBin, IcoPencil, IcoUpload, List, LoadingContent } from '../../../../components';
import { useEffect } from 'react';
import { listarMeusProjetosProdutos, removeListMeuProjetoTab } from '../../redux/meusProjetosActions';
import { modalOpen } from '../../../../layout/redux/layoutActions';
import Add from './add';
import Importar from './importar';
import Grupos from './grupos';
import { IF } from '../../../../helpers';


export default () => {
  const dispatch = useDispatch()
  const {idProjetos, idPasso} = useParams()
  const { sistema } = useSelector(state => state.layoutState)
  const nls = require(`../../nls/${sistema.idioma}.json`)
  const { meuProjeto, paginate } = useSelector(state=> state.meusProjetosState)
  const [form, setForm] = useState({selects: {}})
  const [infomodal, setInfomodal] = useState({selects: {}})
    useEffect(()=> {
      if (idProjetos) {
        dispatch(listarMeusProjetosProdutos(idProjetos))
      }
    }, [idProjetos])

  const headerDefault = [
    {
      column: 'checkboxCustom',
      text: <Checkbox 
        name='all'
        checked={form.selects?.all}
        action={e=> handleCheckboxSelect({name: 'all', value: e})}
      />,
      className: 'actions',
    },
    {
      column: 'nome',
      text: nls.configurar.tableNome,
      className: '',
    },
    {
      column: 'grupo',
      text: 'Grupo',
      className: '',
    },
    {
      column: 'urlAmazon',
      text: 'Amazon',
      className: '',
    },
    {
      column: 'actions',
      text: '',
      className: 'actions',
    }
  ]

  const handleCheckboxSelect = e => {
    if (e.name === 'all') {
      let all = {all: e.value}
      meuProjeto.empresas.map(p=> {  all = {...all, [p.id]: e.value} })
      setForm({...form, selects: all})
    } else {
      setForm({...form, selects: {...form.selects, [e.name]: e.value}})
    }
  }

  const selectedHas = Object.keys(form.selects).filter(p=> form.selects[p] ).length

  const handlePaginate = e => {
    console.warn(e, 'handlePaginate');
  }

  const handlePerPage = e => {
    console.warn(e, 'handlePerPage');
  }
  
  const handleAddModal = e => {
    console.warn(e, 'handlePerPage');
    if (e) {
      setInfomodal(e)
    } else {
      setInfomodal({})
    }
    dispatch(modalOpen('criarProdutoMeuProjeto'))
  }
  
  const handleRemove = e => {
    console.error(e, 'handleRemove');
    const returnedHas = Object.keys(e).filter(f=> f !== 'all' && e[f] === true)
    console.error(returnedHas, 'handleRemove');
    returnedHas.map(id=> {
      dispatch(removeListMeuProjetoTab({id, name: 'produtos'}))
    })
    handleCheckboxSelect({name: 'all', value: false})
  }

  const handleImportarModal = e => {
    console.warn(e, 'handlePerPage');
    dispatch(modalOpen('importarProdutoMeuProjeto'))
  }

  const handleGruposModal = e => {
    console.warn(e, 'handleGruposModal');
    dispatch(modalOpen('gruposProdutoMeuProjeto'))
  }
  
  console.log({idPasso}, 'idPassoidPasso');
  return (
    <div className='box-meus-projetos-produtos'>
      <div className='box-meus-projetos-topinfo'>
        <h5>{nls.configurar.titleProdutosList}</h5>
        <div>
          <Button
            type='link'
            color='default'
            onClick={()=> handleRemove(form.selects)}
            disabled={selectedHas === 0? true:false}
          ><IcoBin /> {nls.configurar.btnExcluir}</Button>
          <Button
            color='primary'
            onClick={()=> handleGruposModal()}
          >{nls.configurar.btnGruposProdutos}</Button>
          <IF se={sistema?.creditoConta?.creditoProdutos}>
            <Button
              color='default'
              onClick={()=> handleImportarModal()}
            ><IcoUpload /> {nls.configurar.btnImportarProdutos}</Button>
            <Button
              color='primary circle'
              onClick={()=> handleAddModal()}
            ><IcoAdd /> {nls.configurar.btnAddProduto}</Button>
          </IF>
        </div>
      </div>
      <LoadingContent
          show={false}
        >
          <List
            header={headerDefault}
            data={meuProjeto.produtos}
            listCustom={e=> {
              const item = e
              item.checkboxCustom = <>
                <Checkbox 
                  name={e.id}
                  checked={form.selects?.[e.id]}
                  action={c=> handleCheckboxSelect({name: e.id, value: c})}
                />
              </>
              item.actions = e.status? <>
                <Button
                  type='link'
                  color='default'
                  onClick={()=> handleAddModal(e)}
                ><IcoPencil /> {nls.configurar.btnEditar}</Button>
              </> :null

              return item
            }}
            paginate={meuProjeto.produtosPaginate}
            actionPaginate={(e)=> handlePaginate(e)}
            handlePerPage={(e)=> handlePerPage(e)}
            nls={nls.paginacao}
          />
        </LoadingContent>
        <Add data={infomodal} />
        <Importar />
        <Grupos />
    </div>
  )
}
